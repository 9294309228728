<template>
  <v-autocomplete
    class="select-audience"
    :chips="!!value"
    required
    :value="value"
    :items="items"
    :item-text="textColumn"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :loading="loading"
    :disabled="disabled"
    v-bind="$attrs"
    @input="onChange"
    :error-messages="errorMessages"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Audience Type
 * ==================================================================================
 **/
import { AUDIENCE_OPTIONS } from '@/utils/enums/Audience'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: 'Select audience',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    errorMessages: {
      type: String,
      default: '',
    },

    valueColumn: {
      type: String,
      default: 'value',
    },

    textColumn: {
      type: String,
      default: 'label',
    },
  },

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    items() {
      return AUDIENCE_OPTIONS
    },
  },

  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
