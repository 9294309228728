<template>
  <v-autocomplete
    class="select-multi-group"
    :chips="value.length > 0"
    required
    v-bind="$attrs"
    :items="validItems"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :item-text="textColumn"
    :value="value"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    :deletable-chips="deletableChips"
    @input="onChange"
    multiple
  >
    <template v-if="isHasSelectAll" v-slot:prepend-item>
      <v-list-item @click="toggleSelectAll">
        <v-list-item-action>
          <v-checkbox :input-value="isSelectAll" color="primary"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-autocomplete>
</template>
<script>
// import { mapActions, mapMutations } from 'vuex'
// import { find } from 'lodash'
import { arraysEqual } from '@/utils/helpers'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    placeholder: {
      type: String,
      default: 'Select building',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'name',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    // buildingId: {
    //   type: [String, Number],
    //   default: null,
    // },

    deletableChips: {
      type: Boolean,
      default: true,
    },
    isHasSelectAll: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      fetching: false,
      properties: [],
      isSelectAll: false,
    }
  },

  watch: {
    value(newValue) {
      this.isSelectAll = arraysEqual(
        this.validItems.map((item) => item.id),
        newValue
      )
    },
  },

  computed: {
    validItems() {
      // let items = [
      //   ...this.items,
      //   ...(this.properties && this.properties.length ? this.properties : []),
      // ]

      // items = items.filter(
      //   (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      // )

      // return !this.viaIndex
      //   ? items
      //   : items.map((item, i) => {
      //       return {
      //         index: i,
      //         ...item,
      //       }
      //     })
      return [
        { id: 1, name: 'Group 1' },
        { id: 2, name: 'Group 2' },
        { id: 3, name: 'Group 3' },
      ]
    },

    viaIndex() {
      return this.valueColumn.toLowerCase() === 'index'
    },
  },

  created() {
    if (this.preFetch) {
      // this.fetch()
    }
  },

  methods: {
    // ...mapActions({
    //   getProperties: 'property/getProperties',
    //   getPropertiesByBuilding: 'property/getPropertiesByBuilding',
    // }),

    // ...mapMutations({
    //   clearProperties: 'property/clearPropertyList',
    // }),

    // async fetch() {
    //   if (this.fetching) return
    //   this.fetching = true
    //   if (this.buildingId) {
    //     await this.getPropertiesByBuilding(this.buildingId)
    //       .then((data) => {
    //         this.properties = data
    //         this.$emit('populated', this.properties)
    //       })
    //       .finally(() => {
    //         this.fetching = false
    //       })
    //   } else {
    //     this.fetching = false
    //   }
    // },

    onChange(value) {
      this.$emit('input', value)
    },

    toggleSelectAll() {
      let value = []
      if (!this.isSelectAll) {
        value = this.validItems.map((item) => item[this.valueColumn])
      }

      this.$emit('input', value)
    },
  },
}
</script>
