<template>
  <v-autocomplete
    class="select-multi-users"
    :chips="value.length > 0"
    required
    multiple
    v-bind="$attrs"
    :items="validItems"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :item-text="textColumn"
    :value="value"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    :deletable-chips="deletableChips"
    @input="onChange"
  >
    <template v-if="isHasSelectAll" v-slot:prepend-item>
      <v-list-item @click="toggleSelectAll">
        <v-list-item-action>
          <v-checkbox :input-value="isSelectAll" color="primary"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-autocomplete>
</template>
<script>
import { mapActions } from 'vuex'
import { find } from 'lodash'
import { arraysEqual } from '@/utils/helpers'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    placeholder: {
      type: String,
      default: 'Select user',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'full_name',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    buildingId: {
      type: [String, Number],
      default: null,
    },

    propertyId: {
      type: [String, Number],
      default: null,
    },

    deletableChips: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    isHasSelectAll: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      fetching: false,
      users: [],
      isSelectAll: false,
    }
  },

  computed: {
    validItems() {
      let items = [
        ...this.items,
        ...(this.users && this.users.length ? this.users : []),
      ]

      items = items.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      )

      return !this.viaIndex
        ? items
        : items.map((item, i) => {
            return {
              index: i,
              ...item,
            }
          })
    },

    viaIndex() {
      return this.valueColumn.toLowerCase() === 'index'
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  watch: {
    value(newValue) {
      this.isSelectAll = arraysEqual(
        this.validItems.map((item) => item.id),
        newValue
      )
    },
    users(newValue) {
      this.isSelectAll = arraysEqual(
        this.validItems.map((item) => item.id),
        this.value
      )
    },
    buildingId(newValue, oldValue) {
      this.fetch()
    },
    propertyId(newValue, oldValue) {
      this.fetch()
    },
  },

  methods: {
    ...mapActions({
      getUsersByFilter: 'user/getUsersByFilter',
    }),

    async fetch() {
      if (this.fetching) return
      this.fetching = true
      if (this.buildingId) {
        await this.getUsersByFilter({
          buildingId: this.buildingId,
        })
          .then((data) => {
            this.users = data
            this.$emit('populated', this.users)
          })
          .finally(() => {
            this.fetching = false
          })
      } else {
        this.fetching = false
      }
    },

    onChange(value) {
      this.$emit('input', value)
    },

    addUser(user) {
      const exist = find(this.users, { id: user.id })
      if (!exist) {
        this.users.push(user)
      }
    },

    updateUser(user) {
      const index = find(this.users, { id: user.id })
      if (index) {
        this.$set(this.users, index, user)
      }
    },

    removeUser(user) {
      const index = find(this.users, { id: user.id })
      if (index) {
        this.users.splice(index)
      }
    },

    toggleSelectAll() {
      let value = []
      if (!this.isSelectAll) {
        value = this.validItems.map((item) => item[this.valueColumn])
      }

      this.$emit('input', value)
    },
  },
}
</script>
