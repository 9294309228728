const ALL_RESIDENTS = {
  label: 'All residents',
  value: 'audience_all_resident',
}
const GROUPS = { label: 'Groups', value: 'audience_groups' }
const PROPERTIES = { label: 'Properties', value: 'audience_properties' }
const USERS = { label: 'Users', value: 'audience_users' }

const AUDIENCE_OPTIONS = [ALL_RESIDENTS, GROUPS, PROPERTIES, USERS]

export { ALL_RESIDENTS, GROUPS, PROPERTIES, USERS, AUDIENCE_OPTIONS }
