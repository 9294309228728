<template>
  <div class="notification-form">
    <v-form ref="form" class="notification-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Building</label>
      <SelectBuilding
        flat
        solo
        required
        class="mt-2"
        v-model="form.building_id"
        :pre-select="!isUpdate"
        :error-messages="form.$getError('building_id')"
        :loading="form.$busy"
        :disabled="form.$busy"
      />

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Audience</label>
      <SelectAudience
        flat
        solo
        required
        class="mt-2"
        v-model="form.audience_type"
        :pre-select="!isUpdate"
        :error-messages="form.$getError('audience_type')"
        :loading="form.$busy"
        :disabled="form.$busy"
        @input="handleSelectAudience"
      />

      <template v-if="hasPropertyPermission && isAudienceProperty">
        <span class="red--text"><strong>* </strong></span
        ><label class="text-field-label">Properties</label>
        <SelectMultiProperties
          flat
          solo
          required
          class="mt-2"
          placeholder="Properties"
          v-model="form.audience_ids"
          :buildingId="form.building_id"
          :pre-select="!isUpdate"
          :error-messages="form.$getError('audience_ids')"
          :loading="form.$busy"
          :disabled="form.$busy"
        />
      </template>

      <template v-if="hasUserPermission && isAudienceUser">
        <span class="red--text"><strong>* </strong></span
        ><label class="text-field-label">Users</label>
        <SelectMultiUsers
          flat
          solo
          required
          class="mt-2"
          placeholder="Users"
          v-model="form.audience_ids"
          :buildingId="form.building_id"
          :pre-select="!isUpdate"
          :error-messages="form.$getError('audience_ids')"
          :loading="form.$busy"
          :disabled="form.$busy"
        />
      </template>

      <template v-if="isAudienceGroup">
        <span class="red--text"><strong>* </strong></span
        ><label class="text-field-label">Groups</label>
        <SelectMultiGroups
          flat
          solo
          required
          class="mt-2"
          placeholder="Groups"
          v-model="form.audience_ids"
          :pre-select="!isUpdate"
          :error-messages="form.$getError('audience_ids')"
          :loading="form.$busy"
          :disabled="form.$busy"
        />
      </template>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">User Profiles</label>
      <SelectMultiProfiles
        flat
        solo
        required
        class="mt-2"
        v-model="form.profile_ids"
        :pre-select="!isUpdate"
        :error-messages="form.$getError('profile_ids')"
        :loading="form.$busy"
        :disabled="form.$busy"
        :show-select-all="true"
        :is-select-all="isSelectAllProfile"
        @select-all="handleSelectAllProfile"
        :placeholder="isSelectAllProfile ? 'All Profiles' : 'Select Profiles'"
      />
      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Content</label>
      <v-textarea
        flat
        solo
        required
        hide-details="auto"
        class="mt-2"
        placeholder="Content"
        v-model="form.notification"
        :error-messages="form.$getError('notification')"
        :loading="form.$busy"
        :disabled="form.$busy"
      />
      <p>{{ remainingCharacters }} recommended characters remaining</p>

      <label class="text-field-label">Schedule Date</label>
      <DatetimePicker
        v-model="form.schedule_send"
        flat
        solo
        required
        class="mt-2 mb-4"
        placeholder="Schedule Date"
        hide-details="auto"
        :error-messages="form.$getError('schedule_send')"
        :loading="form.$busy"
        :disabled="form.$busy"
      />
      <p>Leave blank to send immediately</p>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Expiry Date</label>
      <DatetimePicker
        v-model="form.expires_at"
        flat
        solo
        required
        class="mt-2 mb-4"
        placeholder="Expiry Date"
        hide-details="auto"
        :error-messages="form.$getError('expires_at')"
        :loading="form.$busy"
        :disabled="form.$busy"
      />

      <v-checkbox
        v-model="form.is_active"
        hide-details="auto"
        label="Set as active"
        class="mt-0"
      ></v-checkbox>

      <v-checkbox
        v-model="form.send_push_notif"
        hide-details="auto"
        label="Send push notification"
        class="mt-0"
      ></v-checkbox>

      <div class="mt-4">
        <v-btn
          v-if="hasPermissions"
          type="submit"
          color="primary"
          class="mr-4 px-6"
          height="40px"
          width="100%"
          :loading="form.$busy"
          >{{ buttonLabel }}</v-btn
        >
      </div>
    </v-form>
    <!-- <NotificationReview v-if="!isUpdate" />
    <NotificationStatus v-else /> -->
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Notification Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import DatetimePicker from '@/components/fields/DatetimePicker'
import SelectBuilding from '@/components/fields/SelectBuilding'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import SelectAudience from '@/components/fields/SelectAudience'
import SelectMultiUsers from '@/components/fields/SelectMultiUsers'
import SelectMultiProperties from '@/components/fields/SelectMultiProperties'
import SelectMultiGroups from '@/components/fields/SelectMultiGroups'
import SelectMultiProfiles from '@/components/fields/SelectMultiProfiles'
import {
  PROPERTIES,
  USERS,
  GROUPS,
  ALL_RESIDENTS,
} from '@/utils/enums/Audience'
// import NotificationReview from '@/views/Home/Notifications/components/NotificationReview'
// import NotificationStatus from '@/views/Home/Notifications/components/NotificationStatus'

export default {
  components: {
    DatetimePicker,
    SelectBuilding,
    SelectAudience,
    SelectMultiUsers,
    SelectMultiProperties,
    SelectMultiGroups,
    SelectMultiProfiles,
    // NotificationReview,
    // NotificationStatus,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    notification: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: '',
        notification: '',
        expires_at: '',
        is_active: false,
        send_push_notif: false,
        audience_type: null,
        is_all_residents: false,
        audience_ids: [],
        profile_ids: [],
        schedule_send: '',
        is_all_profiles: true,
      }),
    }
  },

  computed: {
    ...mapState({
      createdNotification: (state) => state.notification.notificationDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Notification Information' : 'Create Notification'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateNotification : this.createNotification
    },

    isUpdate() {
      return !!this.notification
    },

    remainingCharacters() {
      const maxLength = 200 // Adjust this to match the maxlength attribute
      const currentLength = this.form.notification.length
      const remaining = maxLength - currentLength
      return remaining >= 0 ? remaining : 0
    },

    hasPermissions() {
      return validatePermissions(
        [PERMISSION.NOTIFICATIONS_UPDATE],
        this.permissions
      )
    },

    hasPropertyPermission() {
      return validatePermissions([PERMISSION.PROPERTY_VIEW], this.permissions)
    },

    hasUserPermission() {
      return validatePermissions([PERMISSION.USERS_VIEW], this.permissions)
    },

    isAudienceProperty() {
      return this.form.audience_type === PROPERTIES.value
    },

    isAudienceUser() {
      return this.form.audience_type === USERS.value
    },

    isAudienceGroup() {
      return this.form.audience_type === GROUPS.value
    },

    isAllPresident() {
      return (
        !this.isAudienceGroup &&
        !this.isAudienceUser &&
        !this.isAudienceProperty
      )
    },

    isSelectAllProfile() {
      return this.form ? this.form.is_all_profiles : true
    },
  },

  watch: {
    notification(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createNotification: 'notification/createNotification',
      updateNotification: 'notification/updateNotification',
    }),

    initForm() {
      if (this.notification) {
        this.form.building_id = this.notification.building_id
        this.form.notification = this.notification.notification
        this.form.expires_at = this.notification.expires_at
        this.form.is_active = this.notification.is_active
        this.form.send_push_notif = this.notification.send_push_notif
        this.form.is_all_residents = this.notification.is_all_residents
        this.form.audience_type = this.notification.is_all_residents
          ? ALL_RESIDENTS.value
          : this.notification.audience_type || ''
        this.form.audience_ids =
          this.notification.audiences.map((audience) => audience.id) || []
        this.form.is_all_profiles = this.notification.is_all_profiles || false
        this.form.profile_ids =
          this.notification.profiles.map((profile) => profile.id) || []
        this.form.schedule_send = this.notification.schedule_send || ''
      }
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Notification details successfully updated!')
          } else {
            this.showSnackbar('Notification successfully created!')
            this.$router.push({
              name: 'notifications',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form.$data()
      if (this.isUpdate) {
        form.id = this.notification.id
      }

      if (form.audience_type === ALL_RESIDENTS.value) {
        delete form.audience_type
      }
      return form
    },

    handleSelectAudience(value) {
      this.form.audience_type = value
      this.form.audience_ids = []
      this.form.is_all_residents = value === ALL_RESIDENTS.value
    },

    handleSelectAllProfile(value) {
      this.form.is_all_profiles = value
    },
  },
}
</script>
<style lang="scss">
.notification-form {
  display: flex;
  gap: 200px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 50px;
  }

  &__form {
    max-width: 500px;
    min-width: 400px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
}
</style>
